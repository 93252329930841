.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

.grid-list {
    margin-top: 30px;
}

.grid-list>.MuiToolbar-root,
.grid-list>.MuiToolbar-root>.MuiToolbar-root {
    display: contents;
}

.RaList-main-38 {
    margin-top: 30px;
}

.RaBulkActionsToolbar-toolbar-52 .MuiToolbar-root {
    float: left;
    left: 8%;
    position: absolute;
    justify-content: center;
    text-align: center;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}